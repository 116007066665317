@tailwind utilities;

@layer utilities {
  .body-2xl {
    @apply font-proximaNova text-2xl leading-[1.5];
  }
  .body-2xl-semibold {
    @apply font-proximaNova text-2xl font-semibold leading-[1.5];
  }
  .body-xl-semibold {
    @apply font-proximaNova text-xl font-semibold leading-[1.5] !important;
  }
  .body-xl-medium {
    @apply font-proximaNova text-[1.1875rem] font-medium leading-[1.5] !important;
  }
  .body-lg {
    @apply text-md font-proximaNova leading-[1.5] !important;
  }
  .body-lg-medium {
    @apply text-md font-proximaNova font-medium leading-[1.5] !important;
  }
  .body-md-medium {
    @apply font-proximaNova text-base font-medium leading-[1.5] !important;
  }
  .body {
    @apply font-proximaNova text-base leading-[1.5];
  }
  .body-medium {
    @apply font-proximaNova text-base font-medium leading-[1.5] !important;
  }
  .body-xs {
    @apply font-proximaNova text-xs font-normal leading-[1.5] !important;
  }
  .h1 {
    @apply tablet:text-[4rem] laptop:text-[6.25rem] text-[2.75rem] leading-[0.9] tracking-tight !important;
  }
  .h2 {
    @apply tablet:text-[2.5rem] laptop:text-[3.5rem] desktop:text-[4rem] text-[2rem] leading-[0.9] tracking-tighter;
  }
  .h3 {
    @apply tablet:text-[2rem] laptop:text-[2.5rem] text-[1.5rem] font-light leading-[0.95] tracking-tighter;
  }
  .display-semibold {
    @apply tablet:text-[1.75rem] font-proximaNova text-[1.5rem] font-semibold leading-[1.2];
  }
  .display-extrabold {
    @apply laptop:text-[1.85rem] desktop:text-[2.5rem] font-proximaNova text-[1.75rem] font-bold leading-[1];
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
